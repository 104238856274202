
const regelingen = [
	{id:"1", naam: `Omgevingsregeling`, versie: `1.1`, status: `Gepubliceerd`, openbaar: true, datum:'2021-08-03', responsiblePerson: {lastName: `De Boer`}},
	//{id:"2", naam: `Nederlandse Omgevingsvisie`, versie: `2.0`, status: `Gepubliceerd`, openbaar: true, datum:'2021-08-03', responsiblePerson: {lastName:`Janssen`}},
	//{id:"3", naam: `Natura2000 - Drents-Friese Wold & Leggelderveld`, versie: `0.9`, status: `Ontwerp`, openbaar: false, datum:'2021-08-03', responsiblePerson: {lastName:`Janssen`}},
];

const regelingVersies = [
	{
		id:"1", 
		regelingID:"1", 
		versie:"Versie 1", 
		parentID: '', 
		preview: `https://rijk.planoview.nl/rijk/NL.IMRO.mnre1034.Omgevingsregeling-1`, 
		export: true,
		doel:`Instellen Omgevingsregeling`, 
		//status:"Concept", 
		//type:'Formeel vastgesteld', 
		datumOntstaan: (new Date()).toLocaleDateString('NL-nl'), 
		datumGepubliceerd: "", 
		datuminwerkingTreding: "2022-08-03", 
		geldigVan: "2022-08-03", 
		geldigTot: "", 
		responsiblePerson: {lastName: `De Boer`},
		wijzigingsvoorstellen: [],
	},
	{
		id:"2", 
		regelingID:"1", 
		versie:"Versie 2", 
		parentID: '1', 
		preview: 'https://rijk.planoview.nl', 
		export: true,
		doel:`Wijzigingen demo`, 
		//status:"Concept", 
		//type:'Formeel vastgesteld', 
		datumOntstaan: (new Date()).toLocaleDateString('NL-nl'), 
		datumGepubliceerd:"", 
		datuminwerkingTreding:"", 
		geldigVan:"", 
		geldigTot:"", 
		responsiblePerson: {lastName: `Janssen`},
		wijzigingsvoorstellen: [],
	},

	/*{id:"11", regelingID:"1", versie:"a", parentID:'1', gepubliceerd: false, doel:`Mobiliteit`, status:"Concept", type:'Werkversie', datum:'2021-08-03', responsiblePerson: {lastName: `Janssen`}},
	{id:"12", regelingID:"1", versie:"a", parentID:'1', gepubliceerd: false, doel:`Natura2000`, status:"Concept", type:'Werkversie', datum:'2021-08-03', responsiblePerson: {lastName: `Janssen`}},
	{id:"13", regelingID:"1", versie:"a", parentID:'1', gepubliceerd: false, doel:`NNN`, status:"Concept", type:'Werkversie', datum:'2021-08-03', responsiblePerson: {lastName: `Janssen`}},
	{id:"10", regelingID:"1", versie:"1", parentID:'1', gepubliceerd: false, doel:`Natuur`, status:"Concept", type:'Wijzigingsvoorstel', datum:'2021-08-03', responsiblePerson: {lastName: `Bakker`}},
	{id:"14", regelingID:"1", versie:"1", parentID:'1', gepubliceerd: false, doel:`Mobiliteit`, status:"Concept", type:'Wijzigingsvoorstel', datum:'2021-08-03', responsiblePerson: {lastName: `Janssen`}},
	{id:"2", regelingID:"1", versie:"2", parentID:'', gepubliceerd: false, doel:`Omgevingsregeling`, status:"Ontwerp", type:'Formeel vastgesteld', datum:"2021-07-03", responsiblePerson: {lastName: `De Boer`}},
	{id:"9", regelingID:"1", versie:"a", parentID:'2', gepubliceerd: false, doel:`Ruimtelijke ordeningen`, status:"Afgerond", type:'Werkversie', datum:'2021-08-03', responsiblePerson: {lastName: `Janssen`}},
	{id:"15", regelingID:"1", versie:"a", parentID:'2', gepubliceerd: false, doel:`Windmolenparken op zee`, status:"Afgerond", type:'Werkversie', datum:'2021-08-03', responsiblePerson: {lastName: `Janssen`}},
	{id:"16", regelingID:"1", versie:"b", parentID:'15', gepubliceerd: false, doel:`Windmolenparken op zee`, status:"Afgerond", type:'Werkversie', datum:'2021-08-03', responsiblePerson: {lastName: `Janssen`}},
	{id:"7", regelingID:"1", versie:"1", parentID:'2', gepubliceerd: false, doel:`Ruimtelijke ordeningen`, status:"Afgerond", type:'Wijzigingsvoorstel', datum:'2021-08-03', responsiblePerson: {lastName: `Janssen`}},
	{id:"8", regelingID:"1", versie:"1", parentID:'2', gepubliceerd: false, doel:`Duurzame energie`, status:"Afgerond", type:'Wijzigingsvoorstel', datum:'2021-08-03', responsiblePerson: {lastName: `Bakker`}},*/
	//{id:"3", regelingID:"1", versie:"3", parentID:'', onderwerp:`Omgevingsregeling`, status:"Vastgesteld", type:'Formeel vastgesteld', datum:"2021-08-03", responsiblePerson: {lastName: `De Boer`}}, 
	//{id:"4", regelingID:"2", versie:"1", parentID:'', onderwerp:``, status:"", type:'Formeel vastgesteld', datum:'2021-06-03', responsiblePerson: {lastName: `De Boer`}}, 
	//{id:"5", regelingID:"2", versie:"2", parentID:'', onderwerp:``, status:"", type:'Formeel vastgesteld', datum:"2021-08-03", responsiblePerson: {lastName: `De Boer`}},
	//{id:"6", regelingID:"3", versie:"1", parentID:'', onderwerp:``, status:"", type:'Formeel vastgesteld', datum:'2021-08-03', responsiblePerson: {lastName: `De Boer`}},
]



const regelingVersiesWijzigingsVoorstel = [
	{
		id:"1", 
		regelingID:"1", 
		parentID:'1',
		omschrijving: 'Versie 1: Eerste versie OR',
		status:"In behandeling", 
		datumGeimporteerd: (new Date()).toLocaleDateString('NL-nl'), 
		responsiblePerson: {lastName: `De Boer`},
		teVerwerken: false,
		datumVerwerkt: "", 
	},
	{
		id:"2", 
		regelingID:"1", 
		parentID:'2',
		omschrijving: `Versie 1A: Niet zijnde kanalen`,
		status:"In behandeling",  
		datumGeimporteerd: (new Date()).toLocaleDateString('NL-nl'), 
		responsiblePerson: {lastName: `De Boer`},
		teVerwerken: false,
		datumVerwerkt: "", 
	},
	{
		id:"3", 
		regelingID:"1", 
		parentID:'2',
		omschrijving: 'Versie 1B: vrijwaringsgebieden rijksvaarwegen',
		status:"In behandeling",  
		datumGeimporteerd: (new Date()).toLocaleDateString('NL-nl'), 
		responsiblePerson: {lastName: `Bakker`},
		teVerwerken: false,
		datumVerwerkt: "", 
	},
	{
		id:"4", 
		regelingID:"1", 
		parentID:'2',
		omschrijving: 'Versie 1C: OR_wijziging 1c',
		status:"In behandeling",  
		datumGeimporteerd: (new Date()).toLocaleDateString('NL-nl'), 
		responsiblePerson: {lastName: `Janssen`},
		teVerwerken: false,
		datumVerwerkt: "", 
	},
]

const statussen = ['Ontwerp', 'Concept', 'Vastgesteld', 'Gepubliceerd', 'Definitief', 'Vervallen', 'Teruggetrokken']
const persons = [{lastName:`Bakker`}, {lastName: `De Boer`}, {lastName: `Janssen`}]

const procedureData = [
	{naam: 'Opstellen concept', verantwoordelijke: 'Janssen', startDatum: '2021-05-11', datumGepland: '2021-11-11', datumGerealiseerd: '2021-07-28'},
	{naam: 'Annoteren RWS', verantwoordelijke: 'Bakker', startDatum: '2021-07-01', datumGepland: '2021-08-01', datumGerealiseerd: '2021-08-23'},
	{naam: 'Opstellen TR door RWS', verantwoordelijke: 'Bakker', startDatum: '2021-08-01', datumGepland: '2021-09-01', datumGerealiseerd: '2021-10-03'},
	{naam: 'Voorbereiden publicatie', verantwoordelijke: 'De Boer', startDatum: '2021-09-14', datumGepland: '2021-10-01', datumGerealiseerd: '2021-10-11'},
	{naam: 'Publiceren', verantwoordelijke: 'Janssen', startDatum: '2021-11-01', datumGepland: '2021-11-01', datumGerealiseerd: '2021-11-01'}
]

export {regelingen, regelingVersies, regelingVersiesWijzigingsVoorstel, procedureData, statussen, persons} 
