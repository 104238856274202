import React, { Fragment, useState } from 'react'
import { Col, Form, Row, Table } from 'react-bootstrap';
import { DashCircle, PlusCircle } from 'react-bootstrap-icons';
import { useTable } from 'react-table'
import Buttons from '../Buttons';

const WijzigingsvoorstelList = ({dataIn, handleRowClick, handleImport, verwerkWijziging}) => {

	const [showContent, setShowContent] = useState(true)

	const columns = React.useMemo(
		() => [
			{Header: 'Datum geimporteerd', accessor: 'datumGeimporteerd'},
			{Header: `Onderwerp`, accessor: 'omschrijving'},
			{Header: 'Status', accessor: 'status'},
			{Header: `Verantwoordelijke`, accessor: 'responsiblePerson.lastName'},
			{Header: `Te verwerken`, accessor: 'teVerwerken'},
		],
		[]
	)

	const data = React.useMemo(
		() => dataIn,
		[dataIn],
	)

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
	} = useTable({ columns, data })

	let noContent = dataIn.length === 0

	return (
		<Fragment>
			<Row>
				<Col><h4>Wijzigingsvoorstellen</h4></Col>
				<Col>{showContent ? 
					<DashCircle onClick={()=>setShowContent(false)} /> : 
					<PlusCircle onClick={()=>setShowContent(true)} /> 
					} 
				</Col>
			</Row>
			{ showContent && noContent && <div><hr/>Er zijn nog geen wijzigingsvoorstellen geimporteerd.<hr/></div> }
			{ showContent && !noContent && 
				<Table size="sm" {...getTableProps()}>
					<thead>
						{headerGroups.map(headerGroup => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map(column => (
									<th {...column.getHeaderProps()}>
										{column.render('Header')}
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{rows.map(row => {
							prepareRow(row)
							return (
								<tr {...row.getRowProps()}>
									{row.cells.map(cell => {
										if (typeof(cell.value) === 'boolean') {
											return (
												<Form.Check type="checkbox" onClick={() => handleRowClick(row.original)} checked={cell.value}/>
											)
										} else {
										return (
											<td {...cell.getCellProps()}>
												{cell.render('Cell')}
											</td>
										)}
									}
									)}
								</tr>
							)
						})}
					</tbody>
				</Table>
			}
			<Buttons disableWijziging={dataIn.length===0} handleImport={handleImport} verwerkWijziging={verwerkWijziging}/> 
		</Fragment>
	)
}

export default WijzigingsvoorstelList
