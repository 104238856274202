import { Form, Row } from "react-bootstrap";

const ModalFileSelect = ({handleFileSelect}) => {

	return 	(
		<Form>
			<Form.Group as={Row} controlId="formFileSm" className="mb-3">
				<Form.Label collumn>Voeg een bestand toe</Form.Label>
			
					<Form.Control type="file" size="sm" onChange={e => handleFileSelect(e.target.value)}/>
			</Form.Group>
			
		</Form>
	)
}

export default ModalFileSelect;
