import { Fragment, useState } from "react"
import FormButtons from "../form/FormButtons"
import FormHeader from "../form/FormHeader"
import RegelingVersieFormLayout from "./RegelingVersieFormLayout"

const RegelingVersieForm = ({regelingVersie, regelingVersies}) => {

	const [showContent, setShowContent] = useState(false)

	return (
		<Fragment>
			<FormHeader showContent={showContent} setShowContent={setShowContent}/>
			{
			showContent &&
			<Fragment>
				<RegelingVersieFormLayout selectedRegeling={regelingVersie} />
				<FormButtons />
			</Fragment>
			}
		</Fragment>
	)
}

export default RegelingVersieForm
