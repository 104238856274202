import { Fragment, useState } from "react"
import { Col, Row } from "react-bootstrap";
import { DashCircle, PlusCircle } from "react-bootstrap-icons";
import List from "../List"

const ProcedureStappen = ({procedures, handleProcedureStappenRowClick}) => {

	const [showContent, setShowContent] = useState(false)

	const procedureColumns = [
    {Header: 'Naam', accessor: 'naam'},
    {Header: 'Verantwoordelijke', accessor: 'verantwoordelijke'},
    {Header: 'Startdatum', accessor: 'startDatum'},
    {Header: 'Geplande einddatum', accessor: 'datumGepland'},
    {Header: 'Gerealiseerd', accessor: 'datumGerealiseerd'},
  ];

	return (
		<Fragment>
			<Row>
				<Col><h4>Workflow</h4></Col>
				<Col>{showContent ? 
					<DashCircle onClick={()=>setShowContent(false)} /> : 
					<PlusCircle onClick={()=>setShowContent(true)} /> 
					} 
				</Col>
			</Row>
			{showContent && <List columns={procedureColumns} data={procedures} handleRowClick={handleProcedureStappenRowClick}></List>}
		</Fragment>
	)
}

export default ProcedureStappen
