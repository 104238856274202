import React, { Fragment, useState } from 'react'
import { Button, Form, Table } from 'react-bootstrap';
import { useTable } from 'react-table'

const RegelingenVersiesList = ({dataIn, handleRowClick}) => {

	const [selectedRowId, setSelectedRowId] = useState()

	const rowClick = (row)=> {
		handleRowClick(row.original)
		setSelectedRowId(row.original.id)
	}

	const columns = React.useMemo(
		() => [
			{Header: 'Versie', accessor: 'versie'},
			{Header: 'Datum ontstaan', accessor: 'datumOntstaan'},
			{Header: `Doel`, accessor: 'doel'},
			{Header: 'Datum gepubliceerd', accessor: 'datumGepubliceerd'},
			{Header: `Verantwoordelijke`, accessor: 'responsiblePerson.lastName'},
			{Header: '', accessor: 'export'},
			{Header: '', accessor: 'preview'},
			//{Header: 'Status', accessor: 'status'},
			//{Header: `Soort document`, accessor: 'type'},
			//{Header: `Brondocument`, accessor: 'brondocument'},
			//{Header: 'Datum', accessor: 'datum'},
		],
		[]
	)

	const data = React.useMemo(
		() => dataIn,
		[dataIn],
	)

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
	} = useTable({ columns, data })

	return (
		<Fragment>
			{
				dataIn.length === 0 ? 
				<div><hr/>Er is nog geen versie van deze regeling. Maak een nieuwe versie en importeer dan de regelingtekst.<hr/></div> :
				<Table size="sm" {...getTableProps()}>
					<thead>
						{headerGroups.map(headerGroup => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map(column => (
									<th {...column.getHeaderProps()}>
										{column.render('Header')}
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{rows.map(row => {
							prepareRow(row)
							return (
								<tr onClick={() => rowClick(row)} style={{background: `${row.original.id === selectedRowId ? '#ffff00' : '#ffffff'}`}} {...row.getRowProps()}>
									{row.cells.map(cell => {
										if (cell.column.id === 'preview') {
											return (
												<Button variant='info' href={cell.value} target='blank'>Preview</Button>
											)
										} else if (cell.column.id === 'export') {
											return (
												<Button variant='info' onClick={()=>null}>Export</Button>
											)
										} else {
											return (
												<td {...cell.getCellProps()}>
													{cell.render('Cell')}
												</td>
										)}
									}
									)}
								</tr>
							)
						})}
					</tbody>
				</Table>
			}
		</Fragment>
	)
}

export default RegelingenVersiesList
