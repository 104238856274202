import { Fragment } from "react"
import { Button, Col, Form, Row } from "react-bootstrap"
import { regelingen } from "./demo_data"
import Search from "./Search"

const RegelingenList = ({addButtonHandler, type, setSelectedRegelingID}) => {

	let handleAddButton = () => {addButtonHandler(true)}

	return (
	<Fragment>
	<Row style={{marginTop:"10px"}}>
		<Col md="8">
    <Form.Group as={Row}>
				<Form.Label column >Regeling: </Form.Label>
				<Col>
					<Form.Control as="select" custom onChange={(e)=> {
						setSelectedRegelingID(e.target.value)
					}} >
						<option>Selecteer een regeling</option>
						{regelingen.map(regeling => <option value={regeling.id}>{regeling.naam}</option>)}
					</Form.Control>
					
				</Col>
			</Form.Group>
		</Col>
		{
		/*<Col>
			<Search/>
		</Col>
		*/
		}
		<Col>
			{
				<Button variant='success' type="button" onClick={handleAddButton}  >Nieuwe {type}</Button>
			}
		</Col>
	</Row>
			</Fragment>
	)
}

export default RegelingenList