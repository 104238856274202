import { Fragment } from "react";
import { Button, Col, Form } from "react-bootstrap";

const Buttons = ({disableWijziging, handleImport, verwerkWijziging, handleFileSelect}) => {
	//let handleImport = () => {console.log('Importeren');}
	return (
		<Fragment>
			<Col >
				{' '}
				<Button variant='info' onClick={handleImport}>Import</Button>
				{' '}
				<Button variant='info' onClick={verwerkWijziging} disabled={disableWijziging}>Verwerk wijzigingen</Button>
			</Col>
		</Fragment>
	)
}

export default Buttons
