import 'bootstrap/dist/css/bootstrap.min.css';

import React, { useState, Fragment } from 'react'
import { Button, Col, Container, Form, Modal, Row} from 'react-bootstrap';

import './App.css';

import ListHeader from './ListHeader';
import { 
  regelingen as regelingenDemo, 
  regelingVersies as regelingVersiesDemo, 
  regelingVersiesWijzigingsVoorstel as regelingVersiesWijzigingsVoorstelDemo,
  procedureData } from './demo_data';
import RegelingenVersiesList from './regelingVersie/RegelingenVersiesList';
import ProcedureStappen from './procedureStappen/ProcedureStappen';
import Buttons from './Buttons';
import WijzigingsvoorstelList from './wijzigingsvoorstel/WijzigingsvoorstelList';
import RegelingVersieForm from './regelingVersie/RegelingVersieForm';
import { getBrondocumentDescription } from './utils';
import RegelingenList from './RegelingenList';
import ModalApproveChange from './ModalApproveChange';
import ModalFileSelect from './ModalFileSelect';

function App() {
  
  const [regelingen, setRegelingen] = useState(regelingenDemo)
  const [regelingVersies, setRegelingVersies] = useState([])
  const [regelingVersiesWijzigingsVoorstel, setRegelingVersiesWijzigingsVoorstel] = useState([])
  const [procedures, setProcedures] = useState(procedureData)

  const [selectedRegelingID, setSelectedRegelingID] = useState("");
  const [selectedRegelingVersieID, setSelectedRegelingVersieID] = useState("");
  const [importFileName, setImportFileName] = useState("");

  const [makeNew, setMakeNew] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [modalFileSelectIsOpen, setModalFileSelectIsOpen] = useState(false)
  
  const handleRegelingenRowClick = (row) => {
    setSelectedRegelingID(row.id);
    setSelectedRegelingVersieID("");
    setMakeNew(false)
  }

  const handleRegelingVersiesRowClick = (row) => {
    setSelectedRegelingVersieID(row.id);
  }

  const handleProcedureStappenRowClick = (row) => {
    console.log("en een klik")
  }


  const handleNewButtonClick = () => {
    setSelectedRegelingVersieID('')
    setMakeNew(true)
  }

  const regelingColumns = [
    {Header: 'Naam', accessor: 'naam'},
    //{Header: 'Versie', accessor: 'versie'},
  ];
 
   // render van de checkbox
   // https://react-table.tanstack.com/docs/examples/row-selection
  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef();
      const resolvedRef = ref || defaultRef;
  
      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);
  
      return (
        <>
          <input type="checkbox" ref={resolvedRef} {...rest} />
        </>
      );
    }
  );
  
  const addNewRegelingVersie = () => {
    if (regelingVersies.length === 0) {
      setRegelingVersies(regelingVersiesDemo.filter(regelingVersie => regelingVersie.id === "1"))
    }
    if (regelingVersies.length === 1) {
      setRegelingVersies(regelingVersies.concat(regelingVersiesDemo.filter(regelingVersie => regelingVersie.id === "2")))
    }
  }

  const handleImport = () => {
   /* let temp = []
		if (filename.includes('versie1_art2_13_bep_oppwater_kanalen')) {
			temp = kanalen.features
		} else if (filename.includes("versie1_art2_41_2_militaire_schietbanen")) {
			temp = defensieterreinen1a.features
		} else if (filename.includes("versie1a_arr2_12_bep_oppwater_geenkanalen")) {
			temp = geenkanalen.features
		} else if (filename.includes("versie1b_art2_12_vrijwaringsgebieden_vaarwegen")) {
			temp = vrijwaringsgebieden.features
		} else if (filename.includes("versie1c_art2_41_2_militaire schietbanen")) {
			temp = defensieterreinen1c.features
		}*/


    const ids = regelingVersiesWijzigingsVoorstel.map(v=> {return v.id})
    const importVoorstel = regelingVersiesWijzigingsVoorstelDemo
      .filter(item => item.parentID === selectedRegelingVersieID)
      .find(item => !ids.includes(item.id))
    if (importVoorstel) { 
      setRegelingVersiesWijzigingsVoorstel(regelingVersiesWijzigingsVoorstel.concat(importVoorstel))
    }
    
  }

  const verwerkWijziging = () => {
      const oudeRegelingVersiesWijzigingsVoorstel = regelingVersiesWijzigingsVoorstel.slice()
      oudeRegelingVersiesWijzigingsVoorstel.map(item => {if (item.parentID === selectedRegelingVersieID && item.teVerwerken) {item.status = 'Verwerkt'}; return item})
      setRegelingVersiesWijzigingsVoorstel(oudeRegelingVersiesWijzigingsVoorstel)
      setModalIsOpen(false)
  }

  const toggleTeVerwerkenstatus = (row) => {
    const rvwv = regelingVersiesWijzigingsVoorstel.slice()
    rvwv.map(item => {
      if (item.id === row.id) {
        item.teVerwerken = row.teVerwerken ? false : true
      } 
      return item
    })
    setRegelingVersiesWijzigingsVoorstel(rvwv)
  }

  const handleFileSelect = () => {

    console.log(importFileName)
    setModalFileSelectIsOpen(false)
    handleImport()
  }


  return (
    <Container style={{ marginLeft:'10px', width: '100%', maxWidth: '2000px'}}>
      <Row>
        <Col md="4"><RegelingenList type='regeling' setSelectedRegelingID={setSelectedRegelingID} addButtonHandler={() => {console.log(`Maak nieuwe regeling`)}} /></Col>
        <Col md="7"><h1><center>Regelingen-beheer</center></h1></Col>
        <Col md="1"><Button variant='success' size="sm" href="https://od-beheer-gio.idgis.nl" style={{marginTop:"5px"}}>Gio Beheer</Button></Col>
      </Row>
      <Row>
            <Row>
            {
              selectedRegelingID &&
              <Fragment>
                {
                //<h2>{regelingen.find(regeling => regeling.id === selectedRegelingID).naam}</h2>
                }
                <hr/>
                <ListHeader  type='regelingversie' addButtonHandler={addNewRegelingVersie} />
                <RegelingenVersiesList 
                  dataIn={regelingVersies
                    .filter(regelingVersie => regelingVersie.regelingID === selectedRegelingID)
                    .map(regeling => { regeling['brondocument'] = getBrondocumentDescription(regelingVersies, regeling); return regeling })}  
                  handleRowClick={handleRegelingVersiesRowClick}
                />
                <hr />
              </Fragment> 
            }
            </Row>
            <Row>
              <Col xs='6'>
                { 
                //selectedRegelingID && <RegelingForm selectedRegeling={regelingen.find(regeling => regeling.id === selectedRegelingID)} /> 
                }
                { 
                // selectedRegelingID && makeNew && <NewRegelingVersieButtons /> 
                }
                
                { 
                selectedRegelingVersieID && 
                <Fragment>
                  <WijzigingsvoorstelList 
                    dataIn={regelingVersiesWijzigingsVoorstel
                      .filter(regelingVersie => regelingVersie.parentID === selectedRegelingVersieID)
                      .map(regeling => { regeling['brondocument'] = getBrondocumentDescription(regelingVersies, regeling); return regeling })} 
                    handleRowClick={toggleTeVerwerkenstatus}
                    handleImport={()=>setModalFileSelectIsOpen(true)}
                    verwerkWijziging={()=>setModalIsOpen(true)}
                  />
                  <RegelingVersieForm 
                    regelingVersie={regelingVersies
                      .filter(regelingVersie => regelingVersie.id === selectedRegelingVersieID)
                      .map(regeling => { regeling['brondocument'] = getBrondocumentDescription(regelingVersies, regeling); return regeling })[0]}
                  />
                  <br/>
                </Fragment> 
                }
                
              
              </Col>
              <Col xs='6'>
                {
                  selectedRegelingVersieID && <ProcedureStappen procedures={procedures} handleProcedureStappenRowClick={handleProcedureStappenRowClick} />
                }
              </Col>             
            </Row> 
            <Row>
                {
                selectedRegelingVersieID && 
                <Fragment>
                  <hr />
                </Fragment>
              }
              </Row>
            
      </Row>
      <Modal size='xl' show={modalIsOpen} onHide={()=>setModalIsOpen(false)}>
        <Modal.Body>
          <ModalApproveChange versie={selectedRegelingVersieID} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>setModalIsOpen(false)}>
            Annuleren
          </Button>
          <Button variant="primary" onClick={verwerkWijziging}>
            Verwerken
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal  show={modalFileSelectIsOpen} onHide={()=>setModalFileSelectIsOpen(false)}>
        <Modal.Body>
          <ModalFileSelect handleFileSelect={setImportFileName}/>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>setModalFileSelectIsOpen(false)}>
            Annuleren
          </Button>
          <Button variant="primary" onClick={handleFileSelect}>
            Importeren
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default App;
