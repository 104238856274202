import React from 'react'
import { Form, Row, Table } from 'react-bootstrap'
import { useTable } from 'react-table'

const List = (props) => {

  let handleRowClick = (row) => {
    props.handleRowClick(row)
  }

  const columns = React.useMemo(
    () => props.columns,
    [props.columns]
  )

  const data = React.useMemo(
    () => props.data,
    [props.data],
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data })

  return (
    <Row>
    <Table size="sm" {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row)
          return (
            <tr onClick={()=>handleRowClick(row.original)}  {...row.getRowProps()}>
              {row.cells.map(cell => {
                if (typeof(cell.value) == 'boolean') {
                  return (
                    <Form.Check type="checkbox" checked={cell.value}/>
                  )
                } else {
                return (
                  <td {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </td>
                )}
              }
              )}
            </tr>
          )
        })}
      </tbody>
    </Table>
    </Row>
  )
}

export default List;
