import { Fragment } from "react"
import { Col, Form, Row } from "react-bootstrap"
import { persons } from "../demo_data"

const RegelingVersieFormLayout = ({selectedRegeling}) => { 
  if (!selectedRegeling?.id) {
    return null
  }
  
  return (
    <Fragment>
      <Form>
        <Form.Group as={Row}>
          <Form.Label column sm="6">Versie</Form.Label>
          <Col>
            <Form.Control 
              type="text"
              name="versie"
              value= {selectedRegeling.versie}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="6">Ontstaan</Form.Label>
          <Col>
            <Form.Control 
              type="text"
              name="datumOntstaan"
              value= {selectedRegeling.datumOntstaan}
              disabled
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="6">Brondocument</Form.Label>
          <Col>
            <Form.Control 
              type="text"
              name="brondocument"
              value= {selectedRegeling.brondocument}
              disabled
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="6">Verantwoordelijke</Form.Label>
          <Col>
            <Form.Control as="select" custom >
              {<option>{selectedRegeling.responsiblePerson?.lastName}</option>}
              {persons.map(person => <option>{person.lastName}</option>)}
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="6">Doel</Form.Label>
          <Col>
            <Form.Control 
              type="text"
              name="doel"
              value= {selectedRegeling.doel}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="6">Gepubliceerd</Form.Label>
          <Col>
            <Form.Control 
              type="text"
              name="datumGepubliceerd"
              value= {selectedRegeling.datumGepubliceerd}
              disabled
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="6">Treedt in werking op</Form.Label>
          <Col>
            <Form.Control 
              type="text"
              name="datuminwerkingTreding"
              value= {selectedRegeling.datuminwerkingTreding}
              disabled
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="6">Geldig van</Form.Label>
          <Col>
            <Form.Control 
              type="text"
              name="geldigVan"
              value= {selectedRegeling.geldigVan}
              disabled
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="6">Geldig tot</Form.Label>
          <Col>
            <Form.Control 
              type="text"
              name="geldigTot"
              value= {selectedRegeling.geldigTot}
              disabled
            />
          </Col>
        </Form.Group>
        {/*
        <Form.Group as={Row}>
          <Form.Label column sm="6">Status</Form.Label>
          <Col>
            <Form.Control as="select" custom >
              <option>{selectedRegeling.status}</option>
              {statussen.map(status => <option>{status}</option>)}
            </Form.Control>
          </Col>
        </Form.Group>
        */}
        {/*
        <Form.Group as={Row}>
          <Form.Label column sm="6">Openbaar</Form.Label>
          <Col>
            <Form.Check type="checkbox" checked={selectedRegeling.openbaar}/>
          </Col>
        </Form.Group>
        */}
        <br/>
      </Form>
    </Fragment>
  )
}

export default RegelingVersieFormLayout
