import { Fragment } from "react"
import { Table } from "react-bootstrap"

const ModalApproveChange =({versie}) => {
	
	console.log(versie)

	const versie1 = 
		<div> Het betreft een initiele wijziging. Er zijn geen problemen met het verwerken ervan.</div>


	const versie1A = <Fragment>
		<h3>Artikel 2.12 (aanwijzing en geometrische begrenzing beperkingengebieden oppervlaktewaterlichamen in beheer bij het Rijk, <span style={{backgroundColor: "#23fa35"}}>niet zijnde</span><span style={{backgroundColor: "#ff2d03"}}>geen</span> kanalen)</h3>
		<p><span style={{backgroundColor: "#23fa35"}}>De beperkingengebieden met betrekking tot een oppervlaktewaterlichaam in beheer bij het Rijk dat geen kanaal is, zijn de locaties waarvan de geometrische begrenzing is vastgelegd in bijlage III.</span></p>
		<p><span style={{backgroundColor: "#ff2d03"}}>[Gereserveerd]</span></p>

		<p>&nbsp;</p>
		<h2>BIJLAGE III BIJ HOOFDSTUK 2 VAN DEZE REGELING (VERWIJZING NAAR GML-BESTANDEN VOOR WERKINGSGEBIEDEN)</h2>
		<Table bordered>
			<thead>
			<tr>
				<td rowspan="3">
					<p><b>LEGENDA:</b></p>
					<p><b>Artikel</b></p>
					<p>&nbsp;</p>
				</td>
				<td>
					<p><b>Noemer</b></p>
				</td>
			</tr>
			<tr>
				<td>
					<p><b>Indicatief/exact</b></p>
				</td>
			</tr>
			<tr>
				<td>
					<p><b>GIO-id</b></p>
				</td>
			</tr>
			</thead>
			<tbody>
			<tr>
				<td rowspan="3">
					<p>2.12</p>
					<p>&nbsp;</p>
					<p>&nbsp;</p>
				</td>
				<td>
					<p>Aanwijzing en geometrische begrenzing beperkingengebieden oppervlaktewaterlichamen in beheer bij het Rijk, <span style={{backgroundColor: "#ff2d03"}}>geen </span><span style={{backgroundColor: "#23fa35"}}>niet zijnde </span>kanalen</p>
				</td>
			</tr>
			<tr>
				<td>
					<p>Exact</p>
				</td>
			</tr>
			<tr>
				<td>
					<p>/join/id/regdata/mnre1034/2020/or_beperkingengebied_oppervlaktewaterlichaam_geen_kanalen/nld@<span style={{backgroundColor: "#23fa35"}}>2021-07-01</span><span style={{backgroundColor: "#ff2d03"}}>2020-04-01</span></p>
				</td>
			</tr>
			<tr>
				<td rowspan="3">
					<p>2.13</p>
					<p>&nbsp;</p>
					<p>&nbsp;</p>
				</td>
				<td>
					<p>Aanwijzing en geometrische begrenzing beperkingengebieden kanalen in beheer bij het Rijk</p>
				</td>
			</tr>
			<tr>
				<td>
					<p>Exact</p>
				</td>
			</tr>
			<tr>
				<td>
					<p>/join/id/regdata/mnre1034/2020/or_beperkingengebieden_kanalen/nld@2020-04-01</p>
				</td>
			</tr>
			<tr>
				<td rowspan="3">
					<p>2.41, tweede lid</p>
					<p>&nbsp;</p>
					<p>&nbsp;</p>
				</td>
				<td>
					<p>Geometrische begrenzing van de onveilige gebieden bij militaire schietbanen</p>
				</td>
			</tr>
			<tr>
				<td>
					<p>Exact</p>
				</td>
			</tr>
			<tr>
				<td>
					<p>/join/id/regdata/mnre1034/2019/or_defensie_terreinen_schietbanen/nld@2019-11-01</p>
				</td>
			</tr></tbody>
		</Table>


	</Fragment>

	const versie1B = <Fragment>
		<h3>Artikel X</h3>
		<p><span style={{backgroundColor: "#23fa35"}}>De beperkingengebieden met betrekking tot een oppervlaktewaterlichaam in beheer bij het Rijk dat geen kanaal is, zijn de locaties waarvan de geometrische begrenzing is vastgelegd in bijlage III.</span></p>
		<p><span style={{backgroundColor: "#ff2d03"}}>[Gereserveerd]</span></p>
	</Fragment>

	return (
		<Fragment>
			{
			versie === "1" ? versie1 : versie1A
			}

	</Fragment>
	)
}

export default ModalApproveChange;
