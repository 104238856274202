const getBrondocumentDescription = (parentRegelingen, regeling) => {
	// Leesbare beschrijving van het brondocument
	let parent = parentRegelingen.find(parentRegeling => parentRegeling.id === regeling.parentID)
	let brondocument = ''
	if (parent) {
		//brondocument = `${parent?.onderwerp} - ${parent?.status} - ${parent?.versie}`
		brondocument = `${parent.versie}`
	}
	return brondocument
}

export { getBrondocumentDescription }
