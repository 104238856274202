import { Fragment } from "react";
import { Col, Row } from "react-bootstrap";
import { DashCircle, PlusCircle } from "react-bootstrap-icons";

const FormHeader = ({showContent, setShowContent}) => {

  return (
    <Fragment>
      <hr/>
			<Row>
				<Col><h4>Eigenschappen</h4></Col>
				<Col>{showContent ? 
					<DashCircle onClick={()=>setShowContent(false)} /> : 
					<PlusCircle onClick={()=>setShowContent(true)} /> 
					} 
				</Col>
			</Row>
    </Fragment>
  );
}

export default FormHeader
